import * as React from "react"
import { graphql } from 'gatsby'
import { Flex, Box, useColorMode } from "@chakra-ui/react"
import './font.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';

export function Head() {
  return (<>
    <html lang="en" />
    <meta httpEquiv="content-language" content="en" />
    <title>JJR Jewelry</title>
    <meta property="og:title" content="JJR Jewelry" />
    <meta property="og:description" content="Jonathan Joseph Rosse Jewelry" />
    <meta property="og:image" content="/images/og_image.jpg" />
  </>)
}

export const data = graphql`
query HomePageContentful {
  allContentfulType(sort: {order: ASC, fields: order}) {
    nodes {
      piece {
        darkImages {
          resize(width: 1400, quality: 100) {
            src
          }
        }
        lightImages {
          resize(width: 1400, quality: 100) {
            src
          }
        }
        featured
        featuredEnd
        slug
      }
    }
  }
}
`;

const TOTAL_LOOPS = 10;

const IndexPage = ({ data }) => {
  const { colorMode } = useColorMode()

  const selectedIndices = React.useMemo(() => {
    return _.times(TOTAL_LOOPS, () =>
      _.map(data.allContentfulType.nodes, node => {
        const nonFeaturedPieces = node.piece.filter(piece => !piece.featured && !piece.featuredEnd);
        return _.random(0, nonFeaturedPieces.length - 1);
      })
    );
  }, [data.allContentfulType.nodes]);

  const getContentfulPieces = (nodes, colorMode, index) => {
    // Helper function to get image URLs based on colorMode
    const getImageUrls = (piece) => {
      const images = colorMode === "light" ? piece['lightImages'] : piece['darkImages'];
      return images.map(image => image.resize.src);
    };

    // Step 1: Extract and process featured and featuredEnd pieces
    let featuredPieces = [];
    let featuredEndPieces = [];
    let otherPieces = [];

    nodes.forEach(node => {
      node.piece.forEach(piece => {
        if (piece.featured) {
          featuredPieces = featuredPieces.concat(getImageUrls(piece));
        } else if (piece.featuredEnd) {
          featuredEndPieces = featuredEndPieces.concat(getImageUrls(piece));
        } else {
          otherPieces.push(piece);
        }
      });
    });

    // Step 2: Random selection of one piece per type (assuming each node is a type)
    const randomMiddlePieces = nodes.map((node, typeIndex) => {
      const nonFeaturedPieces = node.piece.filter(piece => !piece.featured && !piece.featuredEnd);
      const randomPiece = nonFeaturedPieces[selectedIndices[index][typeIndex]]
      return randomPiece ? getImageUrls(randomPiece) : [];
    }).flat();

    // Step 3: Combine all pieces
    const combinedPieces = _.concat(featuredPieces, randomMiddlePieces, featuredEndPieces);

    return combinedPieces;
  };

  const lightImages = _.flatMap(_.range(TOTAL_LOOPS), i => getContentfulPieces(data.allContentfulType.nodes, 'light', i));
  const darkImages = _.flatMap(_.range(TOTAL_LOOPS), i => getContentfulPieces(data.allContentfulType.nodes, 'dark', i));
  const images = colorMode === "light" ? lightImages : darkImages;

  const carouselRef = React.useRef(null);
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  const TRANSITION_TIME = 2000;
  const onCarouselChange = (index) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, TRANSITION_TIME);

    const oppositeColorModeImages = colorMode === "light" ? darkImages : lightImages;
    const img = new Image();
    img.src = oppositeColorModeImages[index];
  }

  const next = () => {
    if(!isTransitioning){
      carouselRef.current.increment();
    }
  }

  const [autoplay, setAutoplay] = React.useState(false)
  React.useEffect(() => {
    if (window.loaded) {
      setAutoplay(true)
    } else {
      setTimeout(() => {
        setAutoplay(true)
      }, 9000);
    }
  }, []);

  return (
    <Flex position="absolute" zIndex="0" top="0" left="0" right="0" bottom="0" flex="auto" onClick={next}>
      <Carousel ref={carouselRef} swipeable={false} onChange={onCarouselChange} stopOnHover={false} showStatus={false} showIndicators={false} showArrows={false} interval={7000} transitionTime={TRANSITION_TIME} infiniteLoop autoPlay={autoplay} animationHandler="fade">
        {images.map(i => {
          return <Box flex="1" width="100%" height="100%" key={i} backgroundImage={i} backgroundSize="contain" backgroundRepeat="no-repeat" backgroundPosition="center" />
        })}
      </Carousel>
    </Flex >
  )
}

export default IndexPage